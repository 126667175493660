import {defineStore} from 'pinia'

export const useAppStore = defineStore('appstore', () => {
    const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY;
    const currencySign = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
    const country = import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR;
    const country_abbr = import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR;
    const callCenterPhoneNumber = import.meta.env.VITE_LOCALIZATION_CALL_PHONE;
    const monthlySubscriptionFee = import.meta.env.VITE_MARKET_MONTHLY_SUBSCRIPTION_FEE;
    const userVerificationFee = import.meta.env.VITE_MARKET_USER_VERIFICATION_FEE;

    return {
        currency,
        currencySign,
        country,
        country_abbr,
        callCenterPhoneNumber,
        monthlySubscriptionFee,
        userVerificationFee,
    }
})
