<script setup>
import {onMounted, ref} from "vue";
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const tiers = ref({});
const getMarketCredit = () => {
  axios.get('/api/marketTiers').then(resp => {
    tiers.value = resp.data;
  });
};

function selectCreditLimit(limit) {
  limit_amount.value = limit.amount;
  points.value = limit.score;
  selectedLimit.value = limit;
}

const selectedLimit = ref({});
const errors = ref('');
const points = ref('');
const limit_amount = ref('');
const saving = ref(false);

function update() {
  saving.value = true;
  axios.post('/apis/v4/fees/marketCreditLimit',{
    'limit_id': selectedLimit.value.id,
    'minimum_credit_points': points.value,
    'agent_limit':limit_amount.value,
  }).then(()=>{
    saving.value = false;
    getMarketCredit();
    var myOffcanvas = document.getElementById('btn-close')
    myOffcanvas.click();
  }).catch(err=>{
    saving.value = false;
  });
}


onMounted(function () {
  getMarketCredit();
});
</script>

<template>
  <div>
    <div class="table-responsive">
      <table class="table ">
        <thead class="thead-inverse">
        <tr>
          <th>Tier</th>
          <th>Limit</th>
          <th>Points</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="tier in tiers">
          <td scope="row">{{ tier.name }}</td>
          <td>{{currency}}{{ tier.amount.toLocaleString() }}</td>
          <td>{{tier.score}}</td>
          <td>
            <button class="btn btn-outline-secondary btn-sm"
                    @click="selectCreditLimit(tier)"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
            >
              Edit
              <i class="fa fa-pencil-square fa-thin"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <h5 id="offcanvasRightLabel">Edit {{ selectedLimit.level }}</h5>
        <button type="button" id="btn-close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form action="">
          <div class="row g-3">
            <div class="col-12">
              <!--Label: Points, Attributes:points -->
              <div class="form-floating">

                <input type="text" class="form-control" id="points" v-model="points"
                       aria-describedby="points-help"
                       :class="[errors.points ? 'is-invalid': '',!errors.points && Object.keys(errors).length > 1 ? 'is-valid': '']"
                >
                <label class="form-label" for="points">Points</label>
                <div class="invalid-feedback" v-if="errors.points">
                  {{ errors.points.toString() }}
                </div>
              </div>
            </div>
            <div class="col-12">
              <!--Label: Limit Amount, Attributes:limit_amount -->
              <div class="form-floating">

                <input type="text" class="form-control" id="limit_amount" v-model="limit_amount"
                       aria-describedby="limit_amount-help"
                       :class="[errors.limit_amount ? 'is-invalid': '',!errors.limit_amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                >
                <label class="form-label" for="limit_amount">Limit Amount</label>
                <div class="invalid-feedback" v-if="errors.limit_amount">
                  {{ errors.limit_amount.toString() }}
                </div>
              </div>
            </div>
            <div class="col-12">
              <button
                  :disabled="saving"
                  @click="update"
                  class="btn btn-outline-secondary">
                Update
                <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                <i class="fa fa-save fa-thin" v-else></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>