<script setup>

import {onMounted, ref} from "vue";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const charges = ref({});
const errors = ref({});

const daily_fee = ref('');
const daily_fee_max = ref('');
const base_fee = ref('');
const id = ref('');
const saving = ref(false);

function updateSelectedFee(charge) {
  daily_fee.value = charge.daily_fee;
  daily_fee_max.value = charge.daily_fee_max_days;
  base_fee.value = charge.fee;
  id.value = charge.id;
}

function update() {
  axios.post('/apis/v4/charges', {
    daily_fee: daily_fee.value,
    daily_fee_max: daily_fee_max.value,
    base_fee: base_fee.value,
    id: id.value,
  }).then(resp=>{
    location.reload();
  });
}

const getFeeServiceCharge = () => {
  axios.get('/api/spa/am_fees').then(resp => {
    charges.value = resp.data;
  }).catch(err => {
    errors.value = err.response.data.errors;
  });
};
onMounted(function () {
  getFeeServiceCharge();
});

</script>

<template>
  <div>
    <div class="row">
      <div class="d-flex justify-content-end mb-2">
        <!--      <button class="btn btn-secondary">-->
        <!--        Add Fee-->
        <!--        <i class="fa fa-plus-circle fa-thin fa-fade"></i>-->
        <!--      </button>-->
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>Fee</th>
          <th>Base Fee</th>
          <th>Daily Fee</th>
          <th>Daily Fee (Max. Days)</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(charge,index) in charges">
          <td scope="row">{{ index + 1 }}</td>
          <td>{{ charge.name }}</td>
          <td>{{ charge.fee }}%</td>
          <td>{{ charge.daily_fee }}%</td>
          <td>{{ charge.daily_fee_max_days }}</td>
          <td>
            <button class="btn btn-primary"
                    @click="updateSelectedFee(charge)"
                    data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"
            >
              <i class="fa fa-pencil fa-thin"></i>
              Edit
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Edit Service Charge</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <form action="">
          <div class="row g-3">
            <div class="col-md-6">
              <!--Label: Base Fee, Attributes:base_fee -->
              <div class="form-floating">
                <input type="text" class="form-control" id="base_fee" v-model="base_fee"
                       aria-describedby="base_fee-help"
                       :class="[errors.base_fee ? 'is-invalid': '',!errors.base_fee && Object.keys(errors).length > 1 ? 'is-valid': '']"
                >
                <label for="base_fee">Base Fee</label>

                <div class="invalid-feedback" v-if="errors.base_fee">
                  {{ errors.base_fee.toString() }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <!--Label: Daily Fee, Attributes:daily_fee -->
              <div class="form-floating">
                <input type="text" class="form-control" id="daily_fee" v-model="daily_fee"
                       aria-describedby="daily_fee-help"
                       :class="[errors.daily_fee ? 'is-invalid': '',!errors.daily_fee && Object.keys(errors).length > 1 ? 'is-valid': '']"
                >
                <label for="daily_fee">Daily Fee</label>

                <div class="invalid-feedback" v-if="errors.daily_fee">
                  {{ errors.daily_fee.toString() }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <!--Label: Daily Fee (Max), Attributes:daily_fee_max -->
              <div class="form-group">
                <label for="daily_fee_max">Daily Fee (Max)</label>
                <input type="text" class="form-control" id="daily_fee_max" v-model="daily_fee_max"
                       aria-describedby="daily_fee_max-help"
                       :class="[errors.daily_fee_max ? 'is-invalid': '',!errors.daily_fee_max && Object.keys(errors).length > 1 ? 'is-valid': '']"
                       placeholder="Daily Fee (Max)" required>
                <div class="invalid-feedback" v-if="errors.daily_fee_max">
                  {{ errors.daily_fee_max.toString() }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex justify-content-end">
                <button class="btn btn-outline-secondary"
                        :disabled="saving"
                        @click="update">
                  Update
                  <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                  <i class="fa fa-save" v-else></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>