<script setup>

import {onMounted, ref} from "vue";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const commissions = ref("");
const allroles = ref({});
const selectedCommissionForUser = ref({});

/* Form*/
const errors = ref('');
const type = ref('');
const amount = ref('');
const rate = ref('');
const applied_to = ref('');
const activity = ref('');
const saving = ref(false);

/* Form*/

function update() {
  saving.value = true;
  axios.put(`/api/spa/am_commissions/${selectedCommissionForUser.value.id}`, {
    'type': type.value,
    'user_group_role_id': applied_to.value,
    'commission_rate': rate.value,
    'activity': activity.value,
    'commission_amount': amount.value,
  }).then(resp => {
    saving.value = false;
    getCommissions();
    var myOffcanvas = document.getElementById('btn-close')
    myOffcanvas.click();
  }).catch(err => {
    saving.value = false;

  }).finally(() => {
    saving.value = false;
  });
}

const getCommissions = function () {
  axios.get('/api/spa/am_commissions').then(resp => {
    commissions.value = resp.data.data;
    allroles.value = resp.data.usergrouproles;
  });
};
onMounted(function () {
  getCommissions();
});

function selectedCommission(commission) {
  selectedCommissionForUser.value = commission;
  type.value = commission.type;
  rate.value = commission.commission_rate;
  applied_to.value = commission.applied_to_wallet;
  activity.value = commission.activity;
  amount.value = commission.commission_amount;
}
</script>

<template>
  <div>
    <div class="table-responsive">
      <table class="table table-{1:striped|sm|bordered|hover|inverse} table-inverse">
        <thead class="thead-inverse">
        <tr>
          <th>Commission Type</th>
          <!--        <th>Commission Trigger</th>-->
          <th>Amount</th>
          <th>Rate</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(commission,index) in commissions">
          <td>{{ commission.type }}</td>
          <td>{{ currency }}{{ commission.commission_amount }}</td>
          <td>{{ commission.commission_rate }}</td>
          <!--          <td>{{ commission.applied_to_wallet }}</td>-->
          <!--          <td>{{ commission.activity }}</td>-->
          <td>
            <button class="btn btn-outline-secondary btn-sm"
                    @click="selectedCommission(commission)"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
              Edit
              <i class="fa fa-2xs fa-pencil-square"></i>
            </button>
          </td>
          <td>
            <button class="btn btn-outline-secondary btn-sm"
                    @click="selectedCommission(commission)"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasviewinfo" aria-controls="offcanvasviewinfo">
              View Info
              <i class="fa fa-2xs fa-eye"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>


      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Edit</h5>
          <button type="button" id="btn-close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="border border-1 rounded p-2">
            <form action="">
              <div class="row g-3">
                <div class="col-md-12">
                  <!--Label: Commission Type, Attributes:type -->
                  <div class="form-floating">
                    <input type="text" class="form-control" id="type" v-model="type"
                           aria-describedby="type-help"
                           :class="[errors.type ? 'is-invalid': '',!errors.type && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                    <label for="type" class="form-label">Commission Type</label>

                    <div class="invalid-feedback" v-if="errors.type">
                      {{ errors.type.toString() }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <!--Label: Amount, Attributes:amount -->
                  <div class="form-floating">
                    <input type="text" class="form-control" id="amount" v-model="amount"
                           aria-describedby="amount-help"
                           :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                    <label for="amount" class="form-label">Amount</label>

                    <div class="invalid-feedback" v-if="errors.amount">
                      {{ errors.amount.toString() }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <!--Label: Rate, Attributes:rate -->
                  <div class="form-floating">
                    <input type="text" class="form-control" id="rate" v-model="rate"
                           aria-describedby="rate-help"
                           :class="[errors.rate ? 'is-invalid': '',!errors.rate && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                    <label for="rate" class="form-label">Rate</label>

                    <div class="invalid-feedback" v-if="errors.rate">
                      {{ errors.rate.toString() }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <!--Label: Applied To, Attributes:applied_to -->
                  <div class="form-floating">
                    <select type="text" class="form-control" id="applied_to" v-model="applied_to"
                            aria-describedby="applied_to-help"
                            :class="[errors.applied_to ? 'is-invalid': '',!errors.applied_to && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                      <option value="Market Leader Wallet">Market Leader Wallet</option>
                      <option value="Market Other Earnings Wallet">Market Other Earnings Wallet</option>
                      <option value="Market Insurance Contributions Wallet">Market Insurance Contributions Wallet
                      </option>
                      <option value="Market Main Wallet Wallet">Market Main Wallet Wallet</option>
                      <!--                      <option :value="role.id" v-for="role in allroles">{{ role.name }}</option>-->
                    </select>
                    <label for="applied_to" class="form-label">Applied To</label>

                    <div class="invalid-feedback" v-if="errors.applied_to">
                      {{ errors.applied_to.toString() }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <!--Label: Activity, Attributes:activity -->
                  <div class="form-floating">
                    <select type="text" class="form-control" id="activity" v-model="activity"
                            aria-describedby="activity-help"
                            :class="[errors.activity ? 'is-invalid': '',!errors.activity &&
                            Object.keys(errors).length > 1
                             ? 'is-valid': '']"
                    >
                      <option :value="``" disabled>Select Option</option>
                      <option value="No Activity">No Activity</option>
                      <option value="Transactions">Transactions</option>
                      <option value="Members with 100% on time payment">Members with 100% on time payment</option>
                      <!--                      <option :value="role.id" v-for="role in allroles">{{ role.name }}</option>-->
                    </select>
                    <label for="activity" class="form-label">Activity</label>

                    <div class="invalid-feedback" v-if="errors.activity">
                      {{ errors.activity.toString() }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-outline-secondary"
                            :disabled="saving"
                            @click.prevent="update"
                    >
                      Update
                      <i class="fa fa-thin fa-spin fa-spinner" v-if="saving"></i>
                      <i class="fa fa-thin fa-save" v-else></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasviewinfo"
           aria-labelledby="offcanvasviewinfoLabel">
        <div class="offcanvas-header">
          <h5 id="offcanvasviewinfoLabel">Viewing Information</h5>
          <button type="button" id="btn-close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div class="">
            <table class="table table-striped table-sm table-bordered table-inverse">
              <tbody>
              <tr>
                <th>Type</th>
                <td>{{ selectedCommissionForUser.type }}</td>
              </tr>
              <tr>
                <th>Rate</th>
                <td>{{ selectedCommissionForUser.commission_rate }}</td>
              </tr>
              <tr>
                <th>Applied To</th>
                <td>{{ selectedCommissionForUser.applied_to_wallet }}</td>
              </tr>
              <tr>
                <th>Activity</th>
                <td>{{ selectedCommissionForUser.activity }}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td>{{ currency }}{{ selectedCommissionForUser.commission_amount }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>