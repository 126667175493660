<script setup>
import {onMounted, ref} from "vue";
import Swal from "sweetalert2";

const errors = ref([]);
const limit = ref(0);
const currentValue = ref(false);

onMounted(() => {
    axios.get('/apis/v4/settings/global_credit_limit')
        .then(resp => {

            if (resp.data.data.settings['global_credit_limit']) {
                limit.value = resp.data.data.settings['global_credit_limit'];
            }
            if (resp.data.data.settings['active']) {
                currentValue.value = true;
            }
        })
});
const saveLimit = () => {
    axios.post('/apis/v4/settings', {
        'name': 'global_credit_limit',
        'settings': {
            global_credit_limit: limit.value,
            active: true
        }
    }).then(resp => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "The global credit limit has been saved",
            showConfirmButton: false,
            timer: 1500,
            toast: true
        });
    }).catch(err => {
        Swal.fire({
            position: "top-end",
            icon: "error",
            title: "The global credit limit has not been saved",
            showConfirmButton: false,
            timer: 1500,
            toast: true
        });
    });
}
</script>

<template>
    <div>
        <div class="tw-border-b tw-border-gray-200 tw-pb-5">
            <h3 class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Global Credit Limit</h3>
            <p class="tw-mt-2 tw-max-w-4xl tw-text-sm tw-text-gray-500">
                Set up global credit limit that will be applied to all markets that have been verified.
            </p>
        </div>
        <div class="alert alert-info" role="alert" v-if="currentValue">
            Your current global credit limit is set at
            <strong>{{limit}}</strong>
        </div>
        <div>
            <div class="row">
                <div class="col-md-6">
                    <!--Label: Limit, Attributes:limit -->
                    <div class="form-floating">
                        <input type="number" class="form-control" id="limit" v-model="limit"
                               aria-describedby="limit-help"
                               :class="[errors.limit ? 'is-invalid': '',!errors.limit && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <label for="limit">Limit</label>

                        <div class="invalid-feedback" v-if="errors.limit">
                            {{ errors.limit.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3 ">
                    <button class="btn btn-secondary float-right" @click.prevent="saveLimit">
                        Save
                        <i class="fa fa-save"></i>
                    </button>
                </div>
            </div>


        </div>
    </div>
</template>

<style scoped>

</style>
