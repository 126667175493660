<script setup>

</script>

<template>
  <div>
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <th scope="col" >
          <div class="flex items-center gap-x-2"><span
              class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">Name</span></div>
        </th>
        <th scope="col" >
          <div class="flex items-center gap-x-2"><span
              class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">Score</span></div>
        </th>
        <th scope="col" >
          <div class="flex items-center gap-x-2"><span
              class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">Status</span></div>
        </th>
        <th scope="col" >
          <div class="flex items-center gap-x-2"><span
              class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">Created</span>
          </div>
        </th>
        <th scope="col" class="px-6 py-3 text-right"></th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">Inclusion Score</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">10</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">Clearance Score</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">1</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">Gender (Female)</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">3</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">Age ( 20 -29)</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">1</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">Age (30 - 39)</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">2</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">Age ( Above 40)</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">3</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">Field Compliance</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">4</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">3 Months Bank Statement</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">6</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">Collateralized Document / Guarantor</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3">
            <div class="flex items-center gap-x-2">
              <div class="grow"><span class="text-sm text-gray-600 dark:text-gray-400">7</span></div>
            </div>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span
              class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"><svg
              class="w-2.5 h-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16"><path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>Applied</span>
          </div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-3"><span class="text-sm text-gray-600 dark:text-gray-400">25 Jun 2023</span></div>
        </td>
        <td class="h-px w-px whitespace-nowrap">
          <div class="px-6 py-1.5">
            <div class="hs-dropdown relative inline-block [--placement:bottom-right]">
              <button id="hs-table-dropdown-1" type="button"
                      class="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                  <path
                      d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
                </svg>
              </button>
              <div
                  class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden mt-2 divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                  aria-labelledby="hs-table-dropdown-1">
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Rename</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Regenrate Key</a><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    href="#">Disable</a></div>
                <div class="py-2 first:pt-0 last:pb-0"><a
                    class="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                    href="#">Delete</a></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>