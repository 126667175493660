<script setup>
import {onMounted, ref} from "vue";

const tiers = ref({});
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const getCreditLimit = () => {
  axios.get('/api/tiers').then(resp => {
    tiers.value = resp.data;
  });
};
const selectedLimit = ref({});
const errors = ref('');
const points = ref('');
const limit_amount = ref('');
const saving = ref(false);

function update() {
  saving.value = true;
  axios.post('/apis/v4/fees/creditLimit',{
    'limit_id': selectedLimit.value.id,
    'minimum_credit_points': points.value,
    'agent_limit':limit_amount.value,
  }).then(()=>{
    saving.value = false;
    getCreditLimit();
    var myOffcanvas = document.getElementById('btn-close')
    myOffcanvas.click();
  }).catch(err=>{
    saving.value = false;
  });
}

function selectCreditLimit(limit) {
  limit_amount.value = limit.agent_limit;
  points.value = limit.minimum_credit_points;
  selectedLimit.value = limit;
}

onMounted(function () {
  getCreditLimit();
});
</script>

<template>
  <div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Limit</th>
          <th>Credit Points</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="tier in tiers">
          <td scope="row">{{ tier?.level }}</td>
          <td>{{ currency }}{{ tier.agent_limit.toLocaleString() }}</td>
          <td>{{ tier.minimum_credit_points }}</td>
          <td>
            <button class="btn btn-outline-secondary btn-sm"
                    @click="selectCreditLimit(tier)"
                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
            >
              Edit
              <i class="fa fa-pencil-square fa-thin"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>


      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Edit {{ selectedLimit.level }}</h5>
          <button type="button" id="btn-close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <form action="">
            <div class="row g-3">
              <div class="col-12">
                <!--Label: Points, Attributes:points -->
                <div class="form-floating">

                  <input type="text" class="form-control" id="points" v-model="points"
                         aria-describedby="points-help"
                         :class="[errors.points ? 'is-invalid': '',!errors.points && Object.keys(errors).length > 1 ? 'is-valid': '']"
                  >
                  <label class="form-label" for="points">Points</label>
                  <div class="invalid-feedback" v-if="errors.points">
                    {{ errors.points.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <!--Label: Limit Amount, Attributes:limit_amount -->
                <div class="form-floating">

                  <input type="text" class="form-control" id="limit_amount" v-model="limit_amount"
                         aria-describedby="limit_amount-help"
                         :class="[errors.limit_amount ? 'is-invalid': '',!errors.limit_amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                  >
                  <label class="form-label" for="limit_amount">Limit Amount</label>
                  <div class="invalid-feedback" v-if="errors.limit_amount">
                    {{ errors.limit_amount.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button
                    :disabled="saving"
                    @click="update"
                    class="btn btn-outline-secondary">
                  Update
                  <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                  <i class="fa fa-save fa-thin" v-else></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>