<script setup>
import {onMounted, ref} from "vue";
import Swal from "sweetalert2";
import {useAppStore} from "../../../../../../store/app";
import iziToast from "izitoast"
import 'izitoast/dist/css/iziToast.min.css'
const errors = ref([]);
const payout_limit = ref(0);
const currentValue = ref(false);
const savingLimit = ref(false);
const app = useAppStore();
onMounted(() => {
    axios.get('/apis/v4/settings/payout_limit')
        .then(resp => {
            if (resp.data.data.settings['payout_limit']) {
                payout_limit.value = resp.data.data.settings['payout_limit'];
            }
            if (resp.data.data.settings['active']) {
                currentValue.value = true;
            }
        })
});
const saveLimit = () => {
    savingLimit.value = true;
    axios.post('/apis/v4/settings', {
        'name': 'payout_limit',
        'settings': {
            payout_limit: payout_limit.value,
            active: true
        }
    }).then(resp => {
        savingLimit.value = false;
        iziToast.success({
            title: 'Payout Updated',
            position: 'topRight',
            timeout: 1000,
            icon: 'fa fa-solid fa-check',
            balloon: true,
            message: `The global payout limit has been saved`,
        })
    }).catch(err => {
        savingLimit.value = false;
        iziToast.success({
            title: 'Payout Setting Failed',
            position: 'topRight',
            timeout: 1000,
            icon: 'fa fa-solid fa-xmark',
            balloon: true,
            message: `The global payout limit could not be saved`,
        })
    });
}
</script>

<template>
    <div>
        <div class="tw-border-b tw-border-gray-200 tw-pb-5">
            <h3 class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Payout Limit</h3>
            <p class="tw-mt-2 tw-max-w-4xl tw-tracking-wide tw-text">
                This setting controls the maximum amount that can be sent automatically.
                By configuring this setting, funds will be transferred to users' M-PESA or bank accounts up to the
                specified limit. If the amount exceeds this limit, the Finance Officer must manually approve the
                payment.
            </p>
        </div>
        <div>
            <form action="">
                <div class="col-12">
                    <div class="form-group form-control-lg">
                        <div class="input-group mb-3">
                            <span class="input-group-text">
                                {{ app.currencySign }}
                            </span>
                            <input type="text" class="form-control" id="payout_limit" v-model="payout_limit"
                                   aria-describedby="payout_limit-help"
                                   :class="[errors.payout_limit ? 'is-invalid': '',!errors.payout_limit && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                   placeholder="Payout Limit" required>
                            <span class="input-group-text">.00</span>
                        </div>

                        <div class="invalid-feedback" v-if="errors.payout_limit">
                            {{ errors.payout_limit.toString() }}
                        </div>
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end">
                    <button class="btn btn-primary" :disabled="savingLimit" @click.prevent="saveLimit">
                        Save
                        <i class="fa fa-spin fa-spinner" v-if="savingLimit"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
