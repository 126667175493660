<script setup>
import {defineProps, ref} from "vue";
import iziToast from "izitoast"
import 'izitoast/dist/css/iziToast.min.css'

const props = defineProps({
    allroles: Array | Object
})
const selectedRole = ref({});
const errors = ref({});
const phone_number = ref('');
const users = ref('');
const searching = ref(false);
const searchEnabled = ref(false);

function setRole(role) {
    selectedRole.value = role
}

const usersInRole = ref([]);

function getUsers(role) {
    setRole(role);
    axios.get(`/apis/v4/admin/getUsersForRole/${role.id}`).then(resp => {
        usersInRole.value = resp.data
    });
}

const removeUser = (index, user) => {
    removingUser.value[index] = true;
    axios.get(`/apis/v4/admin/removeUserFromRole/${selectedRole.value.id}/${user.id}`)
        .then(resp => {
            removingUser.value =[];
            iziToast.success({
                title: 'User Removed',
                position: 'topRight',
                timeout: 1000,
                icon: 'fa fa-solid fa-check',
                balloon: true,
                message: `User has been removed from this Role`,
            })
            getUsers(selectedRole.value);
        }).catch(err => {
        removingUser.value[index] = false;
        iziToast.error({
            title: 'User Could Not Be Removed',
            position: 'topRight',
            timeout: 3000,
            icon: 'fa fa-solid fa-xmark',
            balloon: true,
            message: `User Could Not Be Removed from this Role`,
        })
    }).finally(() => {
        removingUser.value[index] = false;
    });
};
const removingUser = ref([]);

function searchForUsers() {
    searching.value = true;
    searchEnabled.value = true;
    axios.get('/apis/v4/users', {
        params: {
            search: phone_number.value
        },

    }).then(resp => {
        searching.value = false;
        users.value = resp.data;
    }).catch(err => {
        searching.value = false;
    });
}

function addUser(user) {
    axios.post('/apis/v4/rbac', {
        'user_id': user.id,
        'role_id': selectedRole.value.id
    }).then(resp => {
        location.reload();
    });
}
</script>

<template>
    <div>

        <div class="table-responsive">
            <table class="table table-striped table-bordered">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Role</th>
                    <th>Number of Users</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(role,index) in allroles">
                    <td scope="row">{{ index + 1 }}</td>
                    <td>{{ role.name }}</td>
                    <td>{{ role.users_count }}</td>
                    <td>
                        <a class="btn btn-outline-dark btn-sm tw-ml-3" data-bs-toggle="offcanvas"
                           href="#viewPermissions" role="button"
                           aria-controls="viewPermissions"
                           @click="setRole(role)"
                        >
                            View Permissions
                            <i class="fa fa-eye text-muted"></i>
                        </a>
                        <a class="btn btn-outline-dark btn-sm tw-ml-3" data-bs-toggle="offcanvas"
                           href="#viewUsers" role="button"
                           aria-controls="viewUsers"
                           @click="getUsers(role)"
                        >
                            View Users
                            <i class="fa fa-user text-muted"></i>
                        </a>
                        <a class="btn btn-primary btn-sm tw-ml-3" data-bs-toggle="offcanvas" href="#offcanvasExample"
                           role="button"
                           aria-controls="offcanvasExample"
                           @click="setRole(role)"
                        >
                            Add User
                            <i class="fa fa-plus-circle"></i>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample"
             aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasExampleLabel">Add user to
                    <strong>{{ selectedRole?.name }}</strong>
                </h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <hr>
                <form action="">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" id="phone_number" v-model="phone_number"
                               placeholder="Search phone number"
                               aria-describedby="phone_number-help"
                               :class="[errors.phone_number ? 'is-invalid': '',!errors.phone_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                        <button
                            @click="searchForUsers"
                            :disabled="searching"
                            class="btn btn-outline-secondary" type="button" id="button-addon2">
                            Search
                            <i class="fa fa-search" v-if="!searching"></i>
                            <i class="fa fa-spin fa-spinner" v-else></i>
                        </button>
                    </div>

                    <div class="" v-if="Object.keys(users).length > 0">

                        <div class="table-responsive">
                            <table class="table table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(user,index) in users">
                                    <td scope="row">{{ index + 1 }}</td>
                                    <td>{{ user.name }}</td>
                                    <td>
                                        <button
                                            @click.prevent="addUser(user)"
                                            class="btn btn-outline-dark btn-sm">
                                            Grant Access
                                            <i class="fa fa-plus-circle text-green"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="offcanvas offcanvas-start" tabindex="-1" id="viewPermissions"
             aria-labelledby="viewPermissionsLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="viewPermissionsLabel"><strong>{{ selectedRole.name }}</strong>
                    Permissions</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div>
                    <ol class="list-group list-group-numbered">
                        <li v-for="perm in selectedRole.permissions" class="list-group-item">{{ perm.name }}</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="offcanvas offcanvas-start" tabindex="-1" id="viewUsers"
             aria-labelledby="viewUsersLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="viewUsersLabel"><strong>{{ selectedRole.name }}</strong>
                    Users</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div>
                    <ol class="list-group list-group">
                        <li v-for="(user,index) in usersInRole" class="list-group-item">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div><span class="tw-font-semibold"> {{ index + 1 }}.</span> {{ user.name }}</div>
                                    <div class="text-muted tw-text-sm">{{ user.phone }}</div>

                                </div>
                                <div>
                                    <a class="text-danger" @click="removeUser(index,user)">
                                        <i class="fa fa-spin fa-spinner" v-if="removingUser[index]"></i>
                                        <i class="fa fa-trash" v-else></i>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
